export const DelegateAccessPathname = '/da';
export const NavigationCenterPathname = '/navigation-center';

export type { RouteConfigWrapper } from './routeConfigWrapper';

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  DELEGATE_TOKEN: 'delegate_token',
  ACCOUNT_ID: 'account_id',
  LATEST_SEARCH: 'LATEST_SEARCH',
  LATEST_PATHNAME: 'LATEST_PATHNAME',
};

export const DELEGATE_ACCESS_IFRAME_URL = `%BASE_URL%${DelegateAccessPathname}?c=%COMPONENT%`;

export const ReconciliationPath = '/reconciliation-reports';
export const SettlementPath = `${ReconciliationPath}/settlements`;
export const TransactionPath = '/transactions';
export const PbaTransactionPath = '/pba/transactions';
export const PbaWalletPath = '/pba/wallets';
export const PbaReportsPath = '/pba/reports';
export const PbaTransactionsReportsPath = `${PbaReportsPath}/transactions`;
export const PbaDeviceReportsPath = `${PbaReportsPath}/devices`;
export const PbaClearingTxnReportsPath = `${PbaReportsPath}/clearing/txn`;
export const PbaClearingFileReportsPath = `${PbaReportsPath}/clearing/file`;
export const PbaSettlementTxnReportsPath = `${PbaReportsPath}/settlement/txn`;
export const PbaSettlementFileReportsPath = `${PbaReportsPath}/settlement/file`;
export const TransactionSettlementPath = `${ReconciliationPath}${TransactionPath}`;
export const TransactionReportPathname = '/txn-rpt';
export const CustomerReportPathname = '/cust-rpt';
export const UserPath = '/u/users';
export const UserGroupPath = '/u/user-groups';
export const MerchantPath = '/merchants';
export const CustomerPath = '/customers';
export const userGroupDetailPath = '/user-group-detail';
export const PatLinkPath = '/pat-links';
export const userDetailPath = '/user-detail';
export const auditPath = '/audit';
