/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiClient } from 'app/commons/axios';
import { Auth } from 'aws-amplify';
import { CognitoSignInResult } from '../models/cognitoSignInResult';
import { AuthMetaData } from 'app/models/authMetaData';
// import { CognitoUser } from '@fabric/tsp/src/app/models/cognitoUser';
import { LAST_USED_TIMESTAMP } from 'app/commons/common';
import { removeLocalStorage } from 'app/hooks/auth/utils';
import { aws_user_pools_web_client_id, aws_cognito_region } from 'variables';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { CognitoUser } from 'app/models/cognitoUser';

const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({ region: aws_cognito_region });

export const login = async (loginInfo: {
  userName: string;
  password: string;
}): Promise<{ result: CognitoSignInResult; cognitoUser: CognitoUser }> => {
  const cognitoUser = (await Auth.signIn(loginInfo.userName, loginInfo.password)) as CognitoUser;
  const result: CognitoSignInResult = {
    user: {
      email: loginInfo.userName,
      username: loginInfo.userName,
    },
    challengeName: cognitoUser.challengeName,
  };

  let preferredMFA = null;
  if (cognitoUser.signInUserSession !== null) {
    preferredMFA = await Auth.getPreferredMFA(cognitoUser, {
      bypassCache: true,
    });
    result.preferredMFA = preferredMFA;
  }

  return {
    result,
    cognitoUser,
  };
};

export const logout = async (): Promise<any> => {
  const resp = await Auth.signOut();
  removeLocalStorage({ key: LAST_USED_TIMESTAMP });

  return {
    result: resp,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const currentSession = async (cu: CognitoUser): Promise<any> => {
  const data = await Auth.currentSession();
  const user = await Auth.currentUserInfo();
  return { data, user, cognitoUser: cu };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const completeNewPassword = async (completePassword: {
  userName: string;
  oldPassword: string;
  newPassword: string;
}): Promise<any> => {
  const user = await Auth.signIn(completePassword.userName, completePassword.oldPassword);
  return await Auth.completeNewPassword(user, completePassword.newPassword);
};

export const setupTotp = async (user: CognitoUser): Promise<string> => {
  return await Auth.setupTOTP(user);
};

export const confirmTotp = async (user: CognitoUser, totp: string): Promise<void> => {
  await Auth.verifyTotpToken(user, totp);
  await Auth.setPreferredMFA(user, 'TOTP');
};

type MfaType = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
export const confirmMfaLogin = async (user: CognitoUser, mfaCode: string): Promise<void> => {
  await Auth.confirmSignIn(user, mfaCode, user.challengeName as MfaType);
};

const getAuthMetaData = async (authMetaData: { tenantCode: string }): Promise<{ result: AuthMetaData }> => {
  const resp = await apiClient.get(`/v1/tsp_metadata?tenant_slug=${authMetaData.tenantCode}`);
  return {
    result: resp.data,
  };
};

const generateTenantDelegateTokens = async () => {
  const session = await Auth.currentSession();
  const refreshToken = session.getRefreshToken().getToken();

  // Use the refresh token to get new tokens
  const command = new InitiateAuthCommand({
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    ClientId: aws_user_pools_web_client_id,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  });

  const response = await cognitoIdentityProviderClient.send(command);

  return response.AuthenticationResult;
};

const isValidToken = (token: string | undefined) => {
  if (!token) return false;

  const decoded = jwt.decode(token) as JwtPayload;

  if (!decoded?.exp) return false;

  const isExpired = Date.now() > decoded.exp * 1000;

  if (isExpired) return false;

  return true;
};

export const authService = {
  login,
  logout,
  completeNewPassword,
  getAuthMetaData,
  generateTenantDelegateTokens,
  isValidToken,
  setupTotp,
  confirmTotp,
};
