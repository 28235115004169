/* eslint-disable react/display-name */
import { Redirect } from 'react-router-dom';
import tenantRoutes from '../pages/Tenants/routes';
import entityRoutes from '../pages/Entities/routes';
import auditRoutes from '../pages/Audit/routes';
import customersRoutes from '../pages/Customers/routes';
import merchantsRoutes from '../pages/Merchants/routes';
import transRoutes from '../pages/Transactions/routes';
import dashboardRoutes from 'app/pages/Dashboard/routes';
import reportsRoutes from '../pages/Reports/routes';
import usersRoutes from '../pages/Users/routes';
import { RouteConfigWrapper } from '@fabric/ui/src/commons';
import patLinkRoutes from '../pages/PatLinks/routes';
import pbaTransRoutes from 'app/pages/PbaTransactions/routes';
import pbaReportsRoutes from 'app/pages/PbaReports/routes';
import walletRoutes from '../pages/Wallets/routes';

const errorRoute: RouteConfigWrapper[] = [
  {
    route: { component: () => <Redirect to="/session/404" /> },
  },
];

const routes = [
  ...dashboardRoutes,
  ...usersRoutes,
  ...tenantRoutes,
  ...entityRoutes,
  ...auditRoutes,
  ...customersRoutes,
  ...merchantsRoutes,
  ...transRoutes,
  ...patLinkRoutes,
  ...reportsRoutes,
  ...pbaTransRoutes,
  ...pbaReportsRoutes,
  ...walletRoutes,
  ...errorRoute,
];

export default routes;
