import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUser } from 'app/models/cognitoUser';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { LoginState } from 'app/hooks/auth/utils';

export interface AuthenticationState {
  loginState: LoginState;
  secretKey: string;
  user?: CognitoUser;
  accessToken?: string;
  expiredAt?: number;
  err?: string;
  tenantDelegateTokens?: Record<string, { accessToken?: string; idToken?: string }>;
}

const initialState: AuthenticationState = {
  loginState: LoginState.LOGGED_OUT,
  secretKey: '',
  user: undefined,
  accessToken: undefined,
  err: undefined,
  tenantDelegateTokens: {},
};

const authentication = createSlice({
  name: 'authentication',
  initialState: { ...initialState },
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{
        user: CognitoUser;
        accessToken: CognitoIdToken;
      }>,
    ) => {
      const { user, accessToken } = action.payload;

      state.expiredAt = accessToken.getExpiration();
      state.accessToken = accessToken.getJwtToken();
      state.user = user;
      state.loginState = LoginState.LOGGED_IN;
      state.err = undefined;
    },
    setupTotpSuccess: (state, action: PayloadAction<string>) => {
      const secretKey = action.payload;

      state.secretKey = secretKey;
    },
    loginError: (
      state,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      action: PayloadAction<{ err: any }>,
    ) => {
      const { err } = action.payload;
      state.accessToken = undefined;
      state.user = undefined;
      state.loginState = LoginState.LOGGED_OUT;
      state.err = err.message;
    },
    logout: (state) => {
      state.loginState = LoginState.LOGGED_OUT;
      state.expiredAt = undefined;
      state.accessToken = undefined;
      state.user = undefined;
      state.secretKey = '';
      state.tenantDelegateTokens = {};
    },
    completeNewPassword: (state, action: PayloadAction<{ user: CognitoUser }>) => {
      state.loginState = LoginState.COMPLETING_PASSWORD;
      state.user = action.payload.user;
    },
    mfaSetup: (state, action: PayloadAction<{ user: CognitoUser }>) => {
      state.loginState = LoginState.MFA_SETUP;
      state.user = action.payload.user;
    },
    mfaChallenge: (state, action: PayloadAction<{ user: CognitoUser }>) => {
      state.loginState = LoginState.MFA_CHALLENGE;
      state.user = action.payload.user;
    },
    setTenantDelegateTokens: (
      state,
      action: PayloadAction<{ tenantId: string; accessToken?: string; idToken?: string }>,
    ) => {
      const { tenantId, accessToken, idToken } = action.payload;
      if (!state.tenantDelegateTokens) {
        state.tenantDelegateTokens = {};
      }
      state.tenantDelegateTokens[tenantId] = { accessToken, idToken };
    },
  },
});
export const {
  loginSuccess,
  setupTotpSuccess,
  loginError,
  logout,
  completeNewPassword,
  mfaSetup,
  mfaChallenge,
  setTenantDelegateTokens,
} = authentication.actions;
export default authentication.reducer;
