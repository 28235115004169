import { call, put, takeLatest } from 'redux-saga/effects';
import * as authSvc from 'app/services/authService';
import * as actions from 'app/redux/authentication/authentication.actions';
import {
  completeNewPassword,
  mfaSetup,
  mfaChallenge,
  loginSuccess,
  setupTotpSuccess,
  logout,
} from './authentication.slice';
import { requestFinished, requestStarted } from 'app/redux/ui/ui.slice';
import { enqueueErrorAction } from '../notifier/notifier.actions';
import { getErrorMessage } from '@fabric/ui/src/utils';
import { CognitoUser } from 'app/models/cognitoUser';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* sagas() {
  yield takeLatest(actions.loginAction, loginHandler);
  yield takeLatest(actions.logoutAction, logoutHandler);
  yield takeLatest(actions.completeNewPasswordAction, completePasswordHandler);
  yield takeLatest(actions.setupTotpAction, setupTotpHandler);
  yield takeLatest(actions.confirmTotpAction, confirmTotpHandler);
  yield takeLatest(actions.confirmMfaAction, confirmMfaHandler);
}

function* loginHandler({ payload }: ReturnType<typeof actions.loginAction>) {
  try {
    const { userName, password } = payload;
    const { result, cognitoUser } = yield call(authSvc.login, {
      userName,
      password,
    });
    if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(completeNewPassword({ user: result.user }));
    } else if (result.challengeName === 'MFA_SETUP') {
      yield put(mfaSetup({ user: cognitoUser }));
    } else if (result.challengeName === 'SOFTWARE_TOKEN_MFA') {
      yield put(mfaChallenge({ user: cognitoUser }));
    } else if (result.preferredMFA === 'NOMFA') {
      yield put(mfaSetup({ user: cognitoUser }));
    } else {
      const { data, user } = yield call(authSvc.currentSession, cognitoUser);
      const { idToken } = data;
      yield put(loginSuccess({ accessToken: idToken, user }));
    }
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

function* completePasswordHandler({ payload }: ReturnType<typeof actions.completeNewPasswordAction>) {
  try {
    yield put(requestStarted({ name: actions.completeNewPasswordActionName }));
    const { userName, oldPassword, newPassword } = payload;
    const cognitoUser: CognitoUser = yield call(authSvc.completeNewPassword, {
      userName,
      oldPassword,
      newPassword,
    });
    if (cognitoUser.challengeName === 'MFA_SETUP') {
      yield put(mfaSetup({ user: cognitoUser }));
    } else if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
      yield put(mfaChallenge({ user: cognitoUser }));
    } else {
      const { data, user } = yield call(authSvc.currentSession, cognitoUser);
      const { idToken } = data;
      yield put(loginSuccess({ accessToken: idToken, user }));
      yield put(requestFinished({ name: actions.completeNewPasswordActionName }));
    }
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
    yield put(requestFinished({ name: actions.completeNewPasswordActionName }));
  }
}

function* setupTotpHandler({ payload }: ReturnType<typeof actions.setupTotpAction>) {
  try {
    yield put(requestStarted({ name: actions.setupTotpActionName }));
    const cognitoUser = payload;
    const secretKey: string = yield call(authSvc.setupTotp, cognitoUser);
    yield put(setupTotpSuccess(secretKey));
    yield put(requestFinished({ name: actions.setupTotpActionName }));
  } catch (error) {
    yield put(enqueueErrorAction({ msg: `TOTP setup failed: ${getErrorMessage(error)}` }));
    yield put(requestFinished({ name: actions.setupTotpActionName }));
  }
}

function* confirmTotpHandler({ payload }: ReturnType<typeof actions.confirmTotpAction>) {
  try {
    yield put(requestStarted({ name: actions.confirmTotpActionName }));
    const { user: cognitoUser, totp } = payload;
    yield call(authSvc.confirmTotp, cognitoUser, totp);
    const { data, user } = yield call(authSvc.currentSession, cognitoUser);
    const { idToken } = data;
    yield put(loginSuccess({ accessToken: idToken, user }));
    yield put(requestFinished({ name: actions.confirmTotpActionName }));
  } catch (error: any) {
    yield put(enqueueErrorAction({ msg: `TOTP Confirmation failed: ${getErrorMessage(error)}` }));
    yield put(requestFinished({ name: actions.confirmTotpActionName }));
    if (error.code !== 'CodeMismatchException') {
      yield call(authSvc.logout);
      yield put(logout());
    }
  }
}

function* confirmMfaHandler({ payload }: ReturnType<typeof actions.confirmMfaAction>) {
  try {
    yield put(requestStarted({ name: actions.confirmTotpActionName }));
    const { user: cognitoUser, mfaCode } = payload;
    yield call(authSvc.confirmMfaLogin, cognitoUser, mfaCode);
    const { data, user } = yield call(authSvc.currentSession, cognitoUser);
    const { idToken } = data;
    yield put(loginSuccess({ accessToken: idToken, user }));
    yield put(requestFinished({ name: actions.confirmTotpActionName }));
  } catch (error: any) {
    yield put(enqueueErrorAction({ msg: `MFA Confirmation failed: ${getErrorMessage(error)}` }));
    yield put(requestFinished({ name: actions.confirmTotpActionName }));
    if (error.code !== 'CodeMismatchException') {
      yield call(authSvc.logout);
      yield put(logout());
    }
  }
}

function* logoutHandler() {
  try {
    yield call(authSvc.logout);
    yield put(logout());
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

export default sagas;
