import { PbaWalletPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import DelegateAccessIframe from '../DelegateAccess/iframe';

const walletsRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: PbaWalletPath,
      exact: true,
      component: () => <DelegateAccessIframe title="Wallets" component="wallets" />,
    },
  },
];

export default walletsRoutes;
