/* eslint-disable @typescript-eslint/no-explicit-any */
import DataGridFilterColumn from '../DataGrid/filterColumn';
import { indexOf } from 'lodash';
import { SorterResult } from 'antd/lib/table/interface';

const BreakDateRange = '_';

function getStringDate(date: Date | undefined | null): string {
  if (!date) {
    return '';
  }
  return date.toISOString();
}

enum DateEnum {
  START,
  END,
}

function isDate(d: Date) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    return !isNaN(d.getTime());
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function hasValue(array: any[], value: any) {
  return indexOf(array, value) > -1;
}

function urlFromSearchCriteria(url: string, criterias?: DataGridFilterColumn[]) {
  if (!criterias || criterias.length < 1) {
    return url;
  }

  for (const criteria of criterias) {
    const value = criteria.value || '';
    if (value === '') {
      continue;
    }

    const query = `${criteria.field}=${encodeURIComponent(value as string)}`;
    url = url.includes('?') ? `${url}&${query}` : `${url}?${query}`;
  }

  return url;
}

function buildSortUrl(url: string, sort?: SorterResult<any>[]) {
  if (!sort || sort.length < 1) {
    return url;
  }

  const sortStr = sort
    .filter((s) => s.order)
    .reduceRight((pre, current, currentIndex) => {
      const operator = current.order === 'descend' ? '-' : '+';
      return `${pre}${operator}${current.field}${currentIndex === 0 ? '' : ','}`;
    }, '');

  if (!sortStr || sortStr.length < 1) {
    return url;
  }

  return `${url}&sort=${encodeURIComponent(sortStr)}`;
}

const sanitizeObject = (input: any) => {
  for (const key in input) {
    if (input.hasOwnProperty(key) && typeof input[key] === 'string') {
      input[key] = input[key].trim();
    }
  }

  return input;
};

const extractPath = (urlFragment: string): string => {
  const queryIndex = urlFragment.indexOf('?');
  if (queryIndex === -1) {
    return urlFragment; // No query parameters
  }
  return urlFragment.substring(0, queryIndex);
};

export {
  hasValue,
  urlFromSearchCriteria,
  buildSortUrl,
  BreakDateRange,
  DateEnum,
  getStringDate,
  isDate,
  sanitizeObject,
  extractPath,
};
